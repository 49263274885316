<!--eslint-disable-->
<template>
  <div class="purple-accounts-page">
    <b-card class="" title="Manage Purple Accounts">
      <b-btn
        class="mr-2 float-right"
        style="margin-top: -10px"
        variant="primary"
        @click="$bvModal.show('modal-create-purple-account')"
      >
        Create Purple Account
      </b-btn>

      <b-card-text>
        This page shows a list of all available purple accounts.
      </b-card-text>
    </b-card>

    <b-card class="mb-0" no-body>
      <div class="row p-2 justify-content-start">
        <section class="col-3">
          <label>Enterprise</label>
          <enterprise-search-select
            :selected="selectedEnterprise"
            @change="updateSelectedEnterprise"
          />
        </section>

        <section class="col-3">
          <label>Email</label>
          <b-input-group>
            <b-form-input
              v-model="filters.email"
              class="d-inline-block"
              placeholder="Enter account email..."
              @input="startNewSearch()"
              @keydown.enter="getMorePurpleAccounts(1)"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="getMorePurpleAccounts(1)">
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </section>
      </div>

      <section>
        <b-table
          :current-page="currentPage"
          :fields="headers"
          :items="purpleAccounts"
          :per-page="0"
          class="data-table"
          hover
        >
          <template #cell(password)="data">
            <div @click="toggleShowPassword($event, data.item.id)">
              <span
                v-if="data.item.id === activePassword || showingAllPasswords"
              >
                {{ data.item.password }}
              </span>
              <span v-else>****************</span>
              <i
                :class="
                  data.item.id !== activePassword || showingAllPasswords
                    ? 'fa-eye'
                    : 'fa-eye-slash'
                "
                class="fas ml-1"
              />
            </div>
          </template>

          <template #cell(enterprise_uid)="data">
            <div>
              {{ data.item.enterprise_uid }}
            </div>
          </template>

          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDateTime }}
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-end">
              <b-dropdown
                class="m-0"
                no-caret
                right
                toggle-class="text-decoration-none"
                variant="link"
              >
                <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template>

                <b-dropdown-item-button
                  v-if="$can('Manage', 'All')"
                  class="full-nested-btn"
                  @click="
                    showPurpleAccountModal(
                      data.item,
                      'modal-update-purple-account'
                    )
                  "
                >
                  Update
                </b-dropdown-item-button>

                <b-dropdown-item-button
                  v-if="$can('Manage', 'All')"
                  class="full-nested-btn"
                  @click="
                    showPurpleAccountModal(data.item, 'modal-remove-owner')
                  "
                >
                  Remove owner
                </b-dropdown-item-button>

                <b-dropdown-item-button
                  v-if="$can('Manage', 'All')"
                  class="full-nested-btn"
                  @click="
                    showPurpleAccountModal(
                      data.item,
                      'modal-delete-purple-account'
                    )
                  "
                >
                  Delete
                </b-dropdown-item-button>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
            <b-pagination
              v-model="currentPage"
              :per-page="filters.page_size"
              :total-rows="total_count"
              class="my-0"
              @change="getMorePurpleAccounts"
            />
          </b-col>
          <b-col v-if="total_count === 0" class="ml-2 mt-1">
            <p>No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>

    <b-modal
      id="modal-create-purple-account"
      hide-footer
      title="Add new Purple account"
    >
      <create-purple-account
        @close="closeModals()"
        @refresh="refreshPurpleAccounts()"
      />
    </b-modal>

    <b-modal
      id="modal-update-purple-account"
      hide-footer
      title="Update Purple Account"
    >
      <update-purple-account
        v-if="selectedPurpleAccount"
        :account="selectedPurpleAccount"
        @close="closeModals()"
        @refresh="refreshPurpleAccounts"
      />
    </b-modal>

    <b-modal
      id="modal-delete-purple-account"
      hide-footer
      title="Delete Purple account"
    >
      <delete-modal
        v-if="selectedPurpleAccount"
        :subtitle="selectedPurpleAccount.email"
        note="The purple account will be removed"
        title="Are you sure you wish to delete this purple account?"
        @close="closeModals()"
        @delete="deletePurpleAccount(selectedPurpleAccount)"
      />
    </b-modal>

    <b-modal id="modal-remove-owner" hide-footer title="Remove owner">
      <warning-modal
        ok-variant="success"
        title="Are you sure you wish remove owner from this purple account?"
        @close="closeModals()"
        @ok="removeOwnerFromAccount(selectedPurpleAccount)"
      />
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import DeleteModal from "@/components/modals/DeleteModal.vue";
import warningModal from "@/components/modals/WarningModal.vue";
import PurpleAccountService from "@/services/PurpleAccountService";
import CreatePurpleAccount from "@/views/purpleAccounts/sections/CreatePurpleAccount.vue";
import UpdatePurpleAccount from "@/views/purpleAccounts/sections/UpdatePurpleAccount.vue";
import EnterpriseSearchSelect from "@/components/input/PaginatedSearchSelect/EnterpriseSearchSelect.vue";

export default {
  name: "Accounts",
  components: {
    EnterpriseSearchSelect,
    CreatePurpleAccount,
    UpdatePurpleAccount,
    DeleteModal,
    warningModal,
  },
  props: {},
  data() {
    return {
      selectedPurpleAccount: null,
      headers: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "password",
          label: "Password",
          class: "fixed",
        },
        {
          key: "created_at",
          label: "Created At",
        },
        {
          key: "owner_uid",
          label: "Owner ID",
        },
        {
          key: "enterprise_uid",
          label: "Enterprise UID",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      purpleAccounts: [],
      activePassword: 0,
      showingAllPasswords: false,
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        enterprise_uid: "",
        email: "",
      },
      total_count: 0,
      timeout: null,
      selectedEnterprise: null,
    };
  },
  created() {
    this.getPurpleAccounts();
  },
  methods: {
    startNewSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.refreshPurpleAccounts();
      }, 100);
    },
    refreshPurpleAccounts() {
      this.closeModals();
      this.getPurpleAccounts();
    },
    getMorePurpleAccounts(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getPurpleAccounts();
    },
    getPurpleAccounts() {
      PurpleAccountService.getAccounts(this.filters)
        .then(res => {
          this.purpleAccounts = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        })
        .catch(err => {
          const res = err.response;
          let errorText =
            "Could not get purple accounts, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        });
    },
    deletePurpleAccount(account) {
      PurpleAccountService.deleteAccount(account.id)
        .then(() => {
          this.$toast.success(`Deleted account ${account.email} Successfully`, {
            toastClassName: ["toast-std", "success-toast"],
          });

          this.refreshPurpleAccounts();
        })
        .catch(err => {
          const res = err.response;
          let errorText =
            "Could not delete purple account, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        });
    },
    removeOwnerFromAccount(account) {
      PurpleAccountService.removeOwner(account.id)
        .then(() => {
          this.$toast.success(
            `Removed owner from Purple account: ${account.email} Successfully`,
            {
              toastClassName: ["toast-std", "success-toast"],
            }
          );

          this.refreshPurpleAccounts();
        })
        .catch(err => {
          const res = err.response;
          let errorText =
            "Could not remove owner from Purple account, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        });
    },
    closeModals() {
      this.$bvModal.hide("modal-create-purple-account");
      this.$bvModal.hide("modal-update-purple-account");
      this.$bvModal.hide("modal-delete-purple-account");
      this.$bvModal.hide("modal-remove-owner");
    },
    setSelectedPurpleAccount(account) {
      this.selectedPurpleAccount = account;
    },
    showPurpleAccountModal(account, modalName) {
      this.setSelectedPurpleAccount(account);
      this.$bvModal.show(modalName);
    },
    toggleShowPassword(event, accountId) {
      const classes = event.target.classList;
      if (this.activePassword !== accountId) {
        classes.replace("fa-eye-slash", "fa-eye");
        this.activePassword = accountId;
      } else {
        classes.replace("fa-eye", "fa-eye-slash");
        this.activePassword = 0;
      }
    },
    updateSelectedEnterprise(enterprise) {
      this.selectedEnterprise = enterprise;
      if (!this.selectedEnterprise) {
        this.filters.enterprise_uid = "";
      } else {
        this.filters.enterprise_uid = this.selectedEnterprise.uid;
      }
      this.getMorePurpleAccounts(1);
    }
  },
};
</script>

<style lang="scss"></style>
