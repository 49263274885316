<template>
  <div class="update-purple-account-modal">
    <section class="mb-2">
      <label>Email</label>
      <b-form-input
        v-model="purpleAccount.email"
        class="mb-1"
        placeholder="Enter email*"
        required
        type="email"
      />

      <label>Password</label>
      <b-input-group class="mb-1">
        <b-form-input
          v-model="purpleAccount.password"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Enter password*"
          required
        />
        <b-input-group-append>
          <b-button @click="showPassword = !showPassword">
            <i :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" class="fas" />
          </b-button>
        </b-input-group-append>
      </b-input-group>

      <label>Enterprise</label>
      <enterprise-search-select
        :selected="selectedEnterprise"
        @change="updateSelectedEnterprise"
      />
      <small v-if="showEnterpriseError" class="text-danger">
        Enterprise is required
      </small>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button
        :class="{ disabled: !checkFormValid() }"
        variant="primary"
        @click="updatePurpleAccount()"
      >
        Update purple account
      </b-button>
    </section>
  </div>
</template>

<script>
import PurpleAccountService from "@/services/PurpleAccountService";
import EnterpriseSearchSelect from "@/components/input/PaginatedSearchSelect/EnterpriseSearchSelect.vue";
import EnterpriseService from "@/services/EnterpriseService";

export default {
  name: "UpdatePurpleAccount",
  components: {
    EnterpriseSearchSelect,
  },
  props: {
    account: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showPassword: false,
      purpleAccount: {
        email: "",
        password: "",
        enterprise_uid: "",
        owner_uid: "",
      },
      selectedEnterprise: null,
      showEnterpriseError: false,
    };
  },
  mounted() {
    this.purpleAccount = {
      email: this.$props.account.email,
      password: this.$props.account.password,
      enterprise_uid: this.$props.account.enterprise_uid,
      owner_uid: this.$props.account.owner_uid,
    };
    this.getEnterprise();
  },
  methods: {
    updatePurpleAccount() {
      PurpleAccountService.updateAccount(this.account.id, this.purpleAccount)
        .then(() => {
          this.$toast.success(
            `Updated purple account ${this.purpleAccount.email} Successfully`,
            {
              toastClassName: ["toast-std", "success-toast"],
            }
          );
          this.$emit("refresh");
        })
        .catch(err => {
          const errorMessage =
            err && err.response && err.response.data && err.response.data.error;
          const msg =
            "Could not update purple account, please refresh and try again";
          this.$toast.error(errorMessage ? err.response.data.error : msg, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        });
    },
    checkFormValid() {
      const emailReg = /\S+@\S+\.\S+/;
      const email = emailReg.test(this.purpleAccount.email);
      const password = this.purpleAccount.password.length > 0;

      return email && password && this.selectedEnterprise;
    },
    async getEnterprise() {
      try {
        const res = await EnterpriseService.getEnterprise(
         this.purpleAccount.enterprise_uid,
        );
        this.selectedEnterprise = res.data;
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not get enterprise, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      }
    },
    updateSelectedEnterprise(enterprise) {
      this.selectedEnterprise = enterprise;
      if (!this.selectedEnterprise) {
        this.purpleAccount.enterprise_uid = "";
        this.showEnterpriseError = true;
      } else {
        this.purpleAccount.enterprise_uid = enterprise.uid;
        this.showEnterpriseError = false;
      }
    }
  },
};
</script>

<style scoped></style>
