<template>
  <div class="create-purple-account-modal">
    <section class="mb-2">
      <label>Email</label>
      <b-form-input
        v-model="purpleAccount.email"
        class="mb-1"
        placeholder="Enter email*"
        required
        type="email"
      />

      <label>Password</label>
      <b-input-group class="mb-1">
        <b-form-input
          v-model="purpleAccount.password"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Enter password*"
          required
        />
        <b-input-group-append>
          <b-button @click="showPassword = !showPassword">
            <i :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" class="fas" />
          </b-button>
        </b-input-group-append>
      </b-input-group>

      <label>Enterprise</label>
      <enterprise-search-select
        :selected="selectedEnterprise"
        @change="updateSelectedEnterprise"
      />
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button
        :class="{ disabled: !checkFormValid() }"
        variant="primary"
        @click="createPurpleAccount()"
        >Create new purple account
      </b-button>
    </section>
  </div>
</template>

<script>
import PurpleAccountService from "@/services/PurpleAccountService";
import EnterpriseSearchSelect from "@/components/input/PaginatedSearchSelect/EnterpriseSearchSelect.vue";

export default {
  name: "CreatePurpleAccount",
  components: { EnterpriseSearchSelect },
  data() {
    return {
      showPassword: false,
      purpleAccount: {
        email: "",
        password: "",
        enterprise_uid: "",
        owner_uid: "",
      },
      selectedEnterprise: null,
    };
  },
  methods: {
    createPurpleAccount() {
      PurpleAccountService.createAccount(this.purpleAccount)
        .then(() => {
          this.$toast.success(
            `Created purple account ${this.purpleAccount.email} Successfully`,
            {
              toastClassName: ["toast-std", "success-toast"],
            }
          );
          this.$emit("refresh");
        })
        .catch(err => {
          const errorMessage =
            err && err.response && err.response.data && err.response.data.error;
          const msg =
            "Could not create purple account, please refresh and try again";
          this.$toast.error(errorMessage ? err.response.data.error : msg, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        });
    },
    checkFormValid() {
      const emailReg = /\S+@\S+\.\S+/;
      const email = emailReg.test(this.purpleAccount.email);
      const password = this.purpleAccount.password.length > 0;

      return email && password;
    },
    updateSelectedEnterprise(enterprise) {
      this.selectedEnterprise = enterprise;
      if (!this.selectedEnterprise) {
        this.purpleAccount.enterprise_uid = "";
      } else {
        this.purpleAccount.enterprise_uid = this.selectedEnterprise.uid;
      }
    }
  },
};
</script>

<style scoped></style>
